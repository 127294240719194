.doctor-btn-outline {
    border: 2px solid #c8c9ee;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    text-transform: uppercase;
    font-weight: 500;
    background: #c8c9ee;
    margin-right: 20px;
    cursor: pointer;
}

.doctor-active-btn {
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 20px;
    background: #5156be;
    color: #fff;
    cursor: pointer;
}

.doctor-btn-visited{
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    background: #5156be;
    color: #f6fbfa;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
}

