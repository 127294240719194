
.cust-do-em {
    display: block;
    margin-bottom: 15px;
  }
  
  .cust-do-em input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .cust-do-em label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
  }
  
  .cust-do-em label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 3px solid #0079bf;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .cust-do-em input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }