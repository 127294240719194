.head-line {
    margin: 20px 0 50px;
    padding: 0 15px;
    text-align: center;
}
.subscription-block {
    margin: 0 auto;
    max-width: 870px;
    text-align: center;
}
.head-line p {
    color: #191919;
    font-size: 25px;
    margin-top: 15px;
}
.head-line a {
    color: #000;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }