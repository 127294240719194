.room-view-icon {
    font-size: 50px;
}

.room-view {
    text-align: center;
}

.room-view-status-0 {
    color:#5fef5496
}

.room-view-status-1 {
    color:#ff97b891
}

.room-view-status-2 {
    color:#5c4c4cb3
}

.ipdwardDatePicker .react-date-picker__wrapper {
    border-radius: 5px;
    border: 1px solid #dcdcdc !important;
}
.react-date-picker {
    height: 100% !important;
}
.admission-details-timepicker {
    height: 100% !important;
    overflow: visible !important;
}

.todo-add-icon {
    margin-left: auto;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
}

.to-do-thead {
    z-index: 999;
}

.to-do-table-row .form-control {
    border: none;
}
.react-time-picker__clock--open {
    z-index: 1000;
}
.react-date-picker__calendar--open {
    z-index: 1000;
}
.pdf-preview-modal {
    z-index: 9999;
}

@media (max-width: 767px) and (min-width: 300px) {
    .room-view {
        min-width: unset;
    }
    .ipd-room-view .wardRoomList {
        display: flex !important;
    }
    
}
@media (max-width: 300px) {
    .room-view {
        min-width: unset;
        max-width: 100%;
    }
}