body {
  padding: 0px;
}

#chart_container {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
}

#demo_chart {
  position: relative;
  /* left: 100px; */
}

#y_axis {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  color: #fc0a0a5e;
}
.rickshaw_graph svg {
  background:#121010
}
#x_axis {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  color: #fc0a0a5e;
}
.rickshaw_graph .y_ticks .tick line {
  stroke-width:0px
}
.dot {
  fill: #ffab00;
  stroke: #fff;
}
.rickshaw_graph .x_grid_d3 .tick:nth-child(4n), .rickshaw_graph .y_grid .tick:nth-child(4n+1){
  z-index: -2;
  stroke: #b597975e;
  stroke-width: 1px;
  stroke-dasharray: 0;
}
.rickshaw_graph .x_grid_d3 .tick:nth-child(4n+1), .rickshaw_graph .y_grid .tick:nth-child(4n+4){
  z-index: -2;
  stroke: #8677773b;
  stroke-width: 1px;
  stroke-dasharray: 0;
}
.rickshaw_graph .x_grid_d3 .tick:nth-child(4n+2), .rickshaw_graph .y_grid .tick:nth-child(4n+2){
  z-index: -2;
  stroke: #8677773b;
  stroke-width: 1px;
  stroke-dasharray: 0;
}
.rickshaw_graph .x_grid_d3 .tick:nth-child(4n+3), .rickshaw_graph .y_grid .tick:nth-child(4n+3){
  z-index: -2;
  stroke: #8677773b;
  stroke-width: 1px;
  stroke-dasharray: 0;
}

.rickshaw_graph .x_ticks_d3 .tick {
  stroke: rgb(10, 0, 0);
  opacity: 0.7
}
.rickshaw_graph .x_ticks_d3 text {
  stroke: #FFF;
  opacity: 0.7
}

#x_axis {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
}
.select-field-ecg {
  display: flex;
  margin-left: auto;
  margin-bottom: 10px;
}
.select-field-ecg .form-control {
  min-height: unset;
}

.ecg-heading {
  align-self: center;
}