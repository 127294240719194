.fc-event {
    color: #000 !important;
}

.fc-v-event .fc-event-main-frame,
.fc-v-event .fc-event-main {
    height: 20px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, 
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    position: relative;
    padding: 0px 10px;
}

.fc-daygrid-event-dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
    z-index: 1;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time,
.fc-daygrid-dot-event .fc-event-title {
    z-index: 99;
    padding-top: 5px;
}

.fc-event-resizer{
    cursor: pointer !important;
}