.card {
	border-radius: 20px;
	border: 1px solid #3abe72;
}

.card-header {
	border-radius: 20px !important;
}

.card-header h5 {
	padding: 0px;
}

.card-header h5 p {
	color: #3abe72;
	padding: 0px;
}

.react-time-picker {
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
}

.react-time-picker__wrapper {
	border-radius: 10px;
	padding: 5px;
	border: 1px solid #ccc;
}
.drug-administration-check-circle {
	font-size: 30px;
	/* color: #34e780; */
	text-align: center;
}


.drug-administration-input {
	margin: auto;
}

.camera-icon {
	font-size: 50px;
	color: #34e780;
}



.drug-administration-check-circle .container {
	display: flex;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	justify-content: center;
  }
  .drug-administration-check-circle .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  .drug-admin-checkmark {
	position: absolute;
	top: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
  	border-radius: 15px;
  }
  .drug-administration-check-circle .container:hover input ~ .drug-admin-checkmark {
	background-color: #ccc;
  }
  .drug-administration-check-circle .container input:checked ~ .drug-admin-checkmark {
	background-color: #5156be;
  }
  .drug-admin-checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  .drug-administration-check-circle .container input:checked ~ .drug-admin-checkmark:after {
	display: block;
  }
  
  .drug-administration-check-circle .container .drug-admin-checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45desg);
	transform: rotate(45deg);
  }
.lab-investigation-card {
	border: 2px solid rgba(12, 11, 11, 0.678);
	background-color: #f5f5f6;
	height: 150px;
	padding: 0px;
	border-radius: 5px;
}

.lab-investigation-card .list-unstyled > div .chat-msg-info {
    flex-direction: row-reverse;
}
.lab-investigation-card .list-unstyled .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.lab-investigation-card .list-unstyled .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.lab-investigation-card .list-unstyled .media.received {
	margin-bottom: 20px;
}
.lab-investigation-card .list-unstyled .media.received .media-body .msg-box > div {
	background-color: #fff;
	max-width: 35%;
	height: 60px;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}
.lab-investigation-card .list-unstyled .media-body .msg-box {
	position: relative;
}

.lab-investigation-input-box {
	height: 15%;
}

.lab-investigation-card .list-unstyled {
	overflow-y: scroll;
	height: 75%;
	padding: 10px;
}

.list-footer-div .chat-footer {
	/* background-color: #59bf76; */
	color: #fff !important;
}

.list-footer-div .chat-footer .input-group-prepend i {
	color: #fff !important;
	font-size: 30px;
}

.list-footer-div .chat-footer .input-group-prepend i {
	color: #fff !important;
	font-size: 30px;
}
.list-footer-div .btn.msg-send-btn {
	background: #fff !important;
	color: #5156be;
}

.list-footer-div {
	padding: 5px 0px;
	background-color: #59bf76;
	height: 58px;
}

.file-drop-list {
	min-height: 30px;
	height: 48px;
}
.todoDayTime {
	max-width: 100% !important;
}

.todoStrikeThrough {
	text-decoration: line-through;
}

.ecg-graph-width-appointment {
	width: 100%
}