/* styles.css */

.custom-accordion {
  /* Your styles for the Accordion component */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: -4px -4px 15px rgba(0, 0, 0, 0.2), 4px 4px 15px rgba(0, 0, 0, 0.2);
}

.custom-accordion-summary {
  /* Your styles for the AccordionSummary component */
  background-color: #e0e0e0;
}

.custom-accordion-details {
  /* Your styles for the AccordionDetails component */
  background-color: #ffffff;
  box-shadow: -4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25),
}

.accordian-detail {
  margin-top: 16px;
  /* border: 1px solid rgba(192, 192, 192, 0.25); */
  border-radius: 8px;
}