body {
  margin: 0;
  /* overflow: auto !important; */
  /* overflow: unset !important; */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
body ul {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.select-input-field {
  padding-left: 0px !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: none;
}

body ::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

body {
  /* overflow: scroll; */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

html {
  /* overflow: scroll; */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
