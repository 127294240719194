.menubaricon {
    font-size: 30px;
}
.profile-menubar ul li {
	line-height: inherit;
}
.profile-menubar > ul > li {
	position: relative;
}
.profile-menubar ul li a span,
.profile-menubar ul li a i {
	display: inline-block;
	vertical-align: middle;
    text-align: center;
}
.profile-menubar > ul > li > a {
	display: block;
    color: #FFFFFF;
	padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-menubar > ul > li:hover > a,
.profile-menubar > ul > li.active > a {
	color:#0de0fe;
}
.profile-menubar ul li a i {
    font-size: 26px;
    margin-right: 10px;
    width: 16px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.profile-menubar ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    /* overflow-x: scroll; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
}

.profile-menubar {
    display: -webkit-box !important;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.profile-menubar::-webkit-scrollbar {
    display: none;
}
@media (max-width: 767px){
    /* .profile-menubar ul {
        padding-left: 40%;
    } */
}
@media (min-width: 767px) and (max-width: 1024px) {
    .profile-menubar ul {
        padding-left: 0%;
    }
}
@media (min-width: 512px) and (max-width: 767px) {
    .profile-menubar ul {
        width: initial !important;
    }
}
@media (max-width: 512px) {
    .profile-menubar ul {
        width: initial !important;
    }
    .profile-menubar ul li a i {
        font-size: 12px;
    }
    .profile-menubar ul li a span {
        font-size: 12px;
    }
}
/* @media (min-width: 300px) and (max-width: 512px) {
    .profile-menubar ul {
        padding-left: 60%;
    }
} */