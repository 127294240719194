.active-btn-blue {
	background: #5156be !important;
	color: #fff !important;
}

@media only screen and (max-width: 456px) {
    
    .hb-mobile-screen-chat-footer {
        position: fixed !important;
        bottom: 0 !important;
        right: 0 !important;
        left: 0 !important;
        width: 100% !important;
    }
    .hb-chat-users-list > .hb-chat-scroll {
        max-height: calc(100vh - 50px) !important;
        min-height: calc(100vh - 50px) !important;

    }
    .hb-mobile-screen-chat-scroll {
        max-height: calc(100vh - 100px) !important;
        min-height: calc(100vh - 100px) !important;
    }
    .hb-mobile-style,
    .hb-appointments-card,
    .hb-strength-type-mobile {
        padding: 0;
    }
    .hb-mobile-screen-card {
        margin-bottom: 1.5rem;
    }
    .hb-emed4u-patient-health-graph  {
        margin: 0 !important;
    }
    .hb-mobile-screen-images {
        margin: 0 !important;
    }
    .hb-health-records-main-heading h2 {
        font-size: 13px;
    }

	.hb-accordion-card-appt .hb-card-header {
		background: #fff;
		border-radius: 0px !important;
	  }
	  
	  .hb-accordion-card-appt {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 17px;
		font-weight: 500;
		color: #272b41;
		margin: 0px;
		padding: 0px;
	  }
	  
	  .hb-accordion-viw-apt {
		background: #5156be;
		color: #fff;
		border: none;
		border-radius: 50%;
		padding: 5px 12px;
		align-items: center;
		font-size: 20px;
	  }
	  .hb-patient-detail-info {
		padding: 0px 20px 0px 20px;
	  }
	  .hb-patient-detail-info .hb-patient-details {
		text-align: start;
	  }
	  .hb-patient-detail-info {
		text-align: start;
	  }
	  .hb-patient-detail-tell{
		color: rgb(81 86 190);
		padding: 0px 20px 0px 20px;
		font-weight: 700;
		line-height: 39px;
		font-size: 14px;
	  }
	  .hb-patient-detail-info:hover {
		color: rgb(81 86 190);
	  }
	  
	  .hb-patient-info-modal-close-icon {
		position: sticky;
		margin-left: auto;
		top: 10px;
		right: 10px;
		font-size: 30px;
		color: #888993;
		cursor: pointer;
	  }
}
