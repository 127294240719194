.login-modal-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0 15px;
}
.footer-style-signup {
    color: #2b2b2b;
}
.sign-color {
    color: #2AAB6C;
}
.forget-password-style {
    margin: 1px 0 45px !important;
    text-align: end;
    cursor: pointer;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.button-login-style {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;
}
.button-proced-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-proced-style {
    width: 200px;
    height: 48px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.button-opacity-05 {
    background: linear-gradient(90deg, rgba(9, 126, 183, 0.3) 0%, rgba(42, 171, 108, 0.3) 100%);
    cursor: not-allowed !important;
}

.button-opacity-0 {
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    cursor: pointer !important;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    margin-bottom: 10px;
    color: #5E5E5E;
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #CCCCCC;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}