.mobile-screen-lipid-chart {
    padding: 0 !important;
    margin-right: 15px !important;
}
.mobile-screen-patient-health-graph-hb {
    padding: 0 !important;
    margin-right: 15px !important;
}

@media only screen and (max-width: 456px) {
    .hb-overflow-x-scroll {
        display: flex !important;
    }
    .hb-container-fluid-card {
        margin: 0px !important;
    }
    .hb-health-records-heading {
        display: flex !important;
    }
    .hb-health-chart-card {
        min-width: 200px;
    }
    .hb-overflow-x-scroll-symtoms {
        display: flex !important;
    }
    .hb-current-vitals-div {
        display: flex;
        border-right: none;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .hb-current-vitals-div-last {
        flex-direction: row;
    }
    .hb-current-vitals-chart-card-heading {
        display: flex !important;
    }
    .hb-current-vitals-chart-card-li {
        display: flex !important;
    }
    .hb-dateTimeDiv {
        display: flex !important;
    }
    .hb-dateTimeDiv-details {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    .hb-appointments-col-weekly-div {
        display: block;
    }
    .hb-appointments-col-weekly-div-main {
        display: none;
    }
    .hb-show-graph-details-modal {
        max-width: unset;
    }
    .hb-container-fluid-card {
        padding: 0px !important;
    }
    .hb-health-records-div {
        padding: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px !important;
    }
    .hb-padding-0 {
        padding: 0px !important;
    }
    .hb-health-records-main-heading{
        padding: 0px !important;
    }
    .hb-appointments-col {
        padding: 0px !important;
    }
    .hb-health-records-main-heading h2 {
        font-size: 18px;
    }
    .hb-scroll-icon {
        font-size: 30px;
    }
    .hb-health-records-heading .submit-btn{
        font-size: 12px;
    }
    .hb-overflow-x-scroll {
        padding: 0px !important;
    }
    .hb-lipid-profile-div {
        padding: 0px !important;
    }
}