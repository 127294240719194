.hms-title-one{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 56px;
line-height: 72px;
text-align: center;
color: #2B2B2B;
margin-top: 15px;
}
.hms-image{
width:100%;
filter: drop-shadow(-10.662px -10.662px 39.9826px rgba(192, 192, 192, 0.25)) drop-shadow(10.662px 10.662px 39.9826px rgba(192, 192, 192, 0.25));
}
.hms-we-offer-title{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 42px;
color: #5E5E5E;
line-height: 72px;
text-align: center;
}
.hms-button-main-screen{

    display: flex;
    justify-content: center;
}
.hms-button{
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    border-radius: 8px;
    width: 445px;
    height: 48px;
    display: flex;
    justify-content: center;
}
.hms-button-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    align-self: center;
    display: flex;
    margin: 0;
    
}
.hms-inner-title-one{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;




background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
background-clip: text;
text-fill-color: transparent;
}
.we-offer-main-block{
padding-block: 48px;
}
.hms-inner-title-two{
    font-size: 20px;
    max-width: 450px;

}
.hms-inner-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.hms-inner-block-other{
  width:592px;  
  margin-left: 32px;
}
.hms-check-block{
    display: flex;
    flex-direction: row;
    width:50% ;
    justify-content: space-around;
    padding-inline: 48px;

}
.hms-image-offer{
    width:34px;
    height: 23px;
    margin-top:5px
}
.EMED4U-Benefits-title{
   padding-block: 46px;
   padding-inline: 131px; 
}
.cards-block-main{
    position: relative;
}
.cards-block{
    position: relative;

}
@media only screen and (max-width: 1240px) {

    .cards-block-main{
        position: relative;
        height: 700px;
    }
    .cards-block-main img{
        height: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .hms-title-one{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    }

    .cards-block-main{
        position: relative;
        height: 300px;
    }
    .cards-block-main img{
        height: 100%;
    }

    .EMED4U-Benefits-title{
        padding-block: 0px;
        padding-inline: 0px; 
     }

     .cards-block{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hms-button{
        width: 90%;
    }
    .hms-check-block{
        width:100% ;
    padding-inline: 28px;

    }
}