.Packages-Main-Container{
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    width:100%;
    padding-bottom: 40px;
}
.hms-package-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    padding-top: 32px;
}
.hms-package-title-other{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
}
.hms-package-inner{
    display: flex;
    justify-content: space-between;
    width: 20%;
}
.hms-package-outer{
    display: flex;
    justify-content: center;
    margin-top: 24px;
}
.hms-package-inner-one{
width: 400px;
min-height: 498px;
background: #FFFFFF;
box-shadow: -4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25);
border-radius: 8px;
margin-top: 36px;
}
.hms-package-inner-main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-inline: 0px;
    flex-wrap: wrap;
    margin-top: 30px;
}

.hms-package-inner-title-one{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5E5E5E;
    margin: 0px;
}
.hms-package-inner-one{
    padding-top:16px;
    margin: 10px 12px;

}
.hms-package-amount{
    display: flex;
    justify-content: center;  
    margin-top:34px;
}
.hms-package-amount-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: #2B2B2B;
}
.yearly-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;  
    color: #5E5E5E;
}
.hms-button-main{
    width: 156px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    border-radius: 8px;
    padding:9px;
    display: flex;
    justify-content: center;
}

.button-div{
    display: flex;
    justify-content: center;
}
.extra-style .hms-button-main {
    background: #fff;
    border: 1px solid green;
}

.extra-style .hms-button-text {
    color: #000;
}
.hms-check-div{
    display:flex;
    padding-inline: 24px;
}
.hms-full-control-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
    margin-left:13px;

}
.full-control-div{
    margin-top: 38px;
}
.hms-package-inner-text-one{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #CCCCCC;
}
.hms-package-inner-text-two{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}
.hms-button-text{
    font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-transform: uppercase;

/* white */

color: #FFFFFF;
}
.border-bottom-main{
    display: flex;
    justify-content: center;
    margin-top:16px;
}
.bottom-border {
    height: 4px;
    width: 120px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    display: flex;
    justify-content: center;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 22px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 8px;
    bottom: 0;
    background-color: #CCCCCC;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 0px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    top: -2px;
  }
  
  input:checked + .slider {
    background-color: #CCCCCC;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #CCCCCC;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



@media only screen and (max-width: 767px) {

    .hms-package-inner-main{
        padding-inline: 20px;
        justify-content: center;
    }
}
@media only screen and (max-width: 991px) {
    .hms-package-inner{
        width: 40%;
    }

    .hms-package-inner-main{
        justify-content: center;
    }
    
}
@media only screen and (min-width :320px) and (max-width :480px){
    .hms-package-inner{
        width: 60%;
    }
    .hms-check-div{
        display:flex;
        padding-inline: 15px;
    }
}


