.patient-call-wrapper-mo {
    height: 100%;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 999;
    left: 0;
    bottom: 0;
}

@media only screen and (max-width: 456px) {
    
    .mobile-screen-chat-footer {
        position: fixed !important;
        bottom: 0 !important;
        right: 0 !important;
        left: 0 !important;
        width: 100% !important;
    }
    .chat-users-list > .chat-scroll {
        max-height: calc(100vh - 50px) !important;
        min-height: calc(100vh - 50px) !important;

    }
    .mobile-screen-chat-scroll {
        max-height: calc(100vh - 100px) !important;
        min-height: calc(100vh - 100px) !important;
    }
    .hb-mobile-style,
    .hb-appointments-card,
    .hb-strength-type-mobile {
        padding: 0;
    }
    .hb-mobile-screen-card {
        margin-bottom: 1.5rem;
    }
    .hb-emed4u-patient-health-graph  {
        margin: 0 !important;
    }
    .hb-mobile-screen-images {
        margin: 0 !important;
    }
    .hb-health-records-main-heading h2 {
        font-size: 13px;
    }
}
