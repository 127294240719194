#me {
    width: 100%;
    height: 100%;
}

.video-agora-box {
  height: 100%;
  width: 100%;
  justify-content: center;
}

#video-agora-local {
  /* width: 320px;
  height: 240px;
  margin-right: 75px; */
  width: 100%;
  height: 100%;
  /* margin-right: 75px; */
}

.link-style {
  cursor: pointer;
}

.handleLoginOpen {
  /* background: #2B2B2B !important; */
}
.modal-dialog-centered {
  /* max-width: 47% !important; */
  display: flex !important;
  justify-content: center !important;
}