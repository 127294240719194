/* App Styles */
.App {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, rgba(9, 126, 183, 1) 0%, rgba(42, 171, 108, 1) 100%);
  }
  
  .payment-container {
    width: 300px;
    height: 350px;
    background: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .payment-header h1 {
    margin: 0;
    font-size: 22px;
    color: #333;
  }
  
  .circular-progress {
    position: relative;
    margin: 30px auto;
    width: 160px;
    height: 160px;
  }
  
  .progress-ring {
    transform: rotate(-90deg); /* Start from the top */
  }
  
  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
  
  .payment-details {
    margin: 20px 0;
  }
  
  .payment-details p {
    margin: 5px 0;
    color: #555;
    font-size: 16px;
  }
  
  .retry-button {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
  }
  
  .retry-button:hover {
    background-color: #d32f2f;
  }
  