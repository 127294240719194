.next-carousel-btn {
    justify-content: flex-end;
}
.form-control[readonly] {
    background: transparent !important;
}
.prev-carousel-btn {
    justify-content: flex-start;
}
.follow-up .carousel-inner {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
}
.follow-up {
    margin-bottom: 30px;
}
.medication-form {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 30px 0px;
}
.medication-form label {
    font-weight: 500;
}
.carousel-item-center {
    text-align: center;
}
.slick-center {
    background-color: #5156be;
    padding: 10px 5px;
    text-align: center;
    color: white;
}
.slick-slide {
    text-align: center;
    padding: 0;

}
@media (max-width: 450px){
    .slick-slide {
        padding: 0;
    
    }
    .slick-arrow {
        display: none !important;
    }
    .slide-image {
        min-height: 0;
    }
}
.slick-prev, .slick-next {
    top: 50%;
    box-shadow: unset;
    background: none;
}
.slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: #5156be;
}
.home-main-slider-full .slick-prev:before, .home-main-slider-full .slick-next:before {
    font-size: 30px;
    color: #60cf68;
}
.home-main-slider-full .slick-prev:hover, .home-main-slider-full .slick-prev:focus, .home-main-slider-full .slick-next:hover, .home-main-slider-full .slick-next:focus {
    color: #60cf68;
    box-shadow: unset;
    background: none;
}
.home-main-slider-full .slick-prev:hover:before, .home-main-slider-full .slick-prev:focus:before, .home-main-slider-full .slick-next:hover:before, .home-main-slider-full .slick-next:focus:before {
    color: #60cf68;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: #5156be;
    box-shadow: unset;
    background: none;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
    color: #5156be;
}
.active-check-icon {
    color: #5156be;
}
.inactive-check-icon {
    color: #eeeeee;
}
.no-data-div{
    display: flex;
    align-items: center;
    height: 100px;
}
.no-data-div h4, .no-data-div h5{
    color: #f14747;
}

.medication-edit-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #5156be;
    color: #FFF;
    align-items: center;
    display: flex;
}
.medication-edit-icon:hover{
    color: #FFF;
}
.slick-list {
    padding: 0px 0px !important;
}


@media only screen and (max-width: 456px) {
    .hb-mobile-screen-diagnosis {
        margin: 0 !important;
    }
    .hb-mobile-screen-slider {
        margin-bottom: 15px !important;
    }
}
