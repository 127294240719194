.custom-menu {
    margin-top: 3px;
}

.custom-menu.nav > li > a {
    color: #8a8a8a;
}

.video-call-modal {
    height: 100% !important;
    max-width: 100% !important;
    margin: 0px;
}

.video-call-modal .modal-content {
    height: 100% !important;
    border-radius: 0px;
}

.calling-user-emed {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
    text-align: center;
    justify-content: center;
}



.video-agora-box {
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  
  .video-agora-box video {
    height: 100%;
    width: 100%;
    justify-content: center;
    object-fit: contain !important;
  }

  #video-agora-local {
    /* width: 320px;
    height: 240px;
    margin-right: 75px; */
    width: 100%;
    height: 100%;
    /* margin-right: 75px; */
  }

  @media only screen and (max-width: 456px) {
    
    .mobile-screen-chat-footer {
        position: fixed !important;
        bottom: 0 !important;
        right: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: unset !important;
    }
    .chat-body {
        height: unset !important;
    }
    .chat-users-list > .chat-scroll {
        max-height: calc(100vh - 50px) !important;
        min-height: calc(100vh - 50px) !important;

    }
    .mobile-screen-chat-scroll {
        max-height: calc(100vh - 100px) !important;
        min-height: calc(100vh - 100px) !important;
    }
}
  
  