.ipdwardDatePicker .react-date-picker__wrapper {
    border-radius: 5px;
    border: 1px solid #dcdcdc !important;
}
.react-date-picker {
    height: 100% !important;
}
.admission-details-timepicker {
    height: 100% !important;
    overflow: visible !important;
}

.todo-add-icon {
    margin-left: auto;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
}

.to-do-thead {
    z-index: 999;
}

.to-do-table-row .form-control {
    border: none;
}