.patient-listing input {
    margin-top: 0px;
}

.margin-top-bottom {
    margin: 0.5rem 0;
}

.patient-info-modal-closed-icon {
    position: sticky;
    margin-left: auto;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: #888993;
    cursor: pointer;
    margin-right: 10px;
  }

  .margin-zero{
    margin: 0;
  }
  