.service-discharge-form input {
    margin-top: 0px;
}
.patient-info-modal-close-icon-preview  {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    color: #888993;
    z-index: 999;
}
.button-disabled {
    cursor:default !important;
    background: #9295cb !important;
    border: #9295cb !important;
}