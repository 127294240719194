.emed4u-sub-card {
	margin-bottom: 8px;
}

.emed4u-sub-card .circle-icon {
	background: #c3c5ff;
	height: 40px;
	width: 40px;
	position: absolute;
	top: 40px;
	border-radius: 5px;
	right: 40px;
	align-self: center !important;
	text-align: center !important;
}

.emed4u-sub-card .circle-icon i {
	line-height: 2.4 !important;
}

.bg-primary-transparent i {
	color: #5156be !important;
}

.bg-info-transparent {
	background-color: rgb(222 242 252) !important;
}

.bg-info-transparent i {
	color: rgb(78 194 239) !important;
}

.bg-secondary-transparent {
	background-color: rgb(255 220 228) !important;
}

.bg-secondary-transparent i {
	color: rgb(249 109 140) !important;
}

.media {
	width: 100%;
}

.avatar-lg {
	width: 45px !important;
	height: 45px !important;
	margin-right: 1rem!important;
	margin-top: auto !important;
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 9%) !important;
}

.media-body h5 {
	font-size: 14px;
	color: #383853 !important;
	margin: 0px;
	padding: 0px !important;
}

.media-body p {
	font-size: 13px;
}

span.float-end.badge.badge-success-transparent {
	background-color: rgb(81 86 190) !important;
	color: #fff;
	font-size: 14px;
	font-weight: 500 !important;
	padding: 5px 10px;
	cursor: pointer;
}

.badge-success-transparent-outline {
	background-color: rgb(255 255 255) !important;
	color: #5156be;
	font-size: 14px;
	font-weight: 500 !important;
	padding: 5px 10px;
	cursor: pointer;
	border: 1px solid;
	margin-left: 5px;
}

.min-hei-565 {
	height: 565px;
}

input {
	border-radius: 20px;
	/* margin-top: 10px; */
	padding: 4px 20px;
	border: 1px solid #ccc;
}
.room-view-icon {
    font-size: 50px;
}

.room-view {
    text-align: center;
}

.room-view-status-0 {
    color:#5fef5496
}

.room-view-status-1 {
    color:#ff97b891
}

.room-view-status-2 {
    color:#5c4c4cb3
}
.room-view p{
	margin-top: 10px;
}

@media (max-width: 767px) and (min-width: 300px) {
    .room-view {
        min-width: unset;
    }
    .ipd-room-view .wardRoomList {
        display: flex !important;
		padding-left: 15px;
		padding-right: 15px;
    }
	.ipd-room-view .wardRoomList .col {
		flex-grow: inherit !important;
	}
    
}

.scroll-bar {
    /* overflow: scroll; */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scroll-bar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

@media (max-width: 300px) {
    .room-view {
        min-width: unset;
        max-width: 100%;
    }
	.ipd-room-view .wardRoomList {
		padding-left: 15px;
		padding-right: 15px;
    }
}