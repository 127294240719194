.feedback-inner-container{
    padding:0 60px;
}
.feedback-heading{
    font-weight: 500;
     font-size: 32px; 
     font-family: 'Poppins';
    font-style: normal;
    line-height: 42px;
}
.feedback-textarea{
    height: 129px;
}
.modal-feedback-button-main{
    align-self: center;
    margin-top: 40px;
    margin-bottom: 29px;
}
.modal-feedback-button{
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    border-radius: 8px;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    
}
.modal-feedback-button-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    align-self: center;
    display: flex;
    margin: 0;
}
.feedbackradiogroup{
    display: flex;
    justify-content: space-between;
    /* flex-direction: row; */
    margin-top:17px;
    margin-bottom:12px;
    padding: 0 15px;
}
.feedback-radio-group-inner{
    display: flex;
    flex-direction: row;
    justify-content:space-between;  
}
.feedback-radio-group-inner-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #5E5E5E;
margin: 0px !important;
}
.feedbackinnertitleone{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #5E5E5E; 
    margin-top:24px ;
}
.feedbackinnertitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E; 
}
.feedbackinnertitlethree{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;  
    color: #5E5E5E; 
    margin-top:38px;
}
.feedbackinnertitleother{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 46px;
    color: #5E5E5E; 
    text-align:center;
}

.input-field-component-textarea{
    margin-top:16px;
}
.input-field-component{
    margin-top:10px;
}
.input-field-feedback{
    background: #F8F7F7;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    height:48px;
    
}
.feedback-reviews{
    margin-top: 22px;
    margin-bottom: 62px;
}
.ContactUsModal{
    overflow: scroll;
}
.feedbackradiogroup{

}
.new-input-field .form-group{
margin-bottom:0px;
}
@media only screen and (max-width: 767px) {
    .feedback-heading{
        font-size: 24px;  
        font-weight: 700;
        line-height: 32px;
    }
    .feedbackinnertitleone{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        margin-top:24px 0px 0px;
    }
    .feedbackinnertitle{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;  
    }
    .feedbackinnertitlethree{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px; 
        margin: 24px 0 0;
    }
    .feedbackinnertitleother{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;  
        margin-top: 32px;
    }
    .modal-feedback-button-text{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;  
    }
    .feedbackradiogroup{
        margin-top:8px;
    margin-bottom:8px; 
    }
    .feedback-inner-container{
        padding:0 0px;
    }
    .feedback-radio-group-inner-text{
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
        }
        .feedback-radio-group-inner{
           margin-top: 8px; 
        }
        .feedback-reviews{
            margin-top: 12px;
            margin-bottom: 38px;
        }
        .input-field-component-textarea{
            margin-top:24px;
        }
        .modal-feedback-button{
            border-radius: 6px;
            width: 172px;
            height: 32px;
            margin-bottom: 25px;
            margin-top: 8px;
            
        }
        .feedback-textarea{         
            height: 74px;         
            background: #F8F7F7;
            border: 1px solid #CCCCCC;
            border-radius: 6px;
        }
        .input-field-feedback{
            
            border-radius: 6px;
            height:32px;
            
        }
        .modal-feedback-button-main{
            align-self: center;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .input-field-component .form-control{
            min-height: 0px;
        }
}