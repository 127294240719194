.custom-menu {
    margin-top: 3px;
}

.custom-menu.nav > li > a {
    color: #8a8a8a;
}

.video-call-modal {
    height: 100% !important;
    max-width: 100% !important;
    margin: 0px;
}

.video-call-modal .modal-content {
    height: 100% !important;
    border-radius: 0px;
}

.calling-user-emed {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
    text-align: center;
    justify-content: center;
}



.video-agora-box {
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  
  #video-agora-local {
    /* width: 320px;
    height: 240px;
    margin-right: 75px; */
    width: 100%;
    height: 100%;
    /* margin-right: 75px; */
  }
  
  .chat-cont-dashboard {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .chat-cont-dashboard .chat-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .chat-cont-dashboard .chat-footer {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .chat-cont-dashboard .doctor-active-btn {
      padding: 5px !important;
      margin-right: 0px !important;
      font-size: 12px;
  }

  .chat-cont-dashboard .material-icons{
      font-size: 30px !important;
  }
  .chat-cont-dashboard .chat-header .media .media-body .user-name {
      font-size: 13px;
  }
  .chat-cont-dashboard .chat-header .media .media-img-wrap .avatar {
    height: 40px !important;
    width: 40px !important;
  }