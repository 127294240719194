.search-div input {
    margin-top: 0px;
}

@media only screen and (max-width: 456px) {
    .mobile-screen-padding {
        padding-left: 0 !important;
    }
    .hb-mobile-screen-button {
        position: absolute !important;
        bottom: 10px !important;
        left: 250px !important;
    }
}
