.doctor-btn-outline {
    border: 1px solid #c8c9ee;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    text-transform: uppercase;
    font-weight: 500;
    background: transparent;
    margin-right: 20px;
    cursor: pointer;
}

.doctor-active-btn {
    border: 1px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 20px;
    background: #1dbe72;
    color: #fff;
    cursor: pointer;
}

.doctor-panel-filters {
    align-items: center;
}

.doctor-panel-filters button {
    margin-top: 30px;
}

.doctor-w-100 {
    width: 100%;
}

.btns-group-patient {
    justify-content: space-around;
}

.doctor-symptoms {
    display: grid;
    padding-left: 25px;
}

.doctor-symptoms span{
    margin-right: 20px;
    color: #56be76;
    font-size: 16px;
    /* overflow-wrap: anywhere; */
}

.fc-timegrid-event .fc-event-main {
    padding: 0px;
    margin: 0px;
    margin-top: -5px;
}
.doctor-btn-outline {
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    text-transform: uppercase;
    font-weight: 500;
    background: transparent;
    margin-right: 20px;
    cursor: pointer;
}

.doctor-active-btn {
    border: 1px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 20px;
    background: #5156be;
    color: #fff;
    cursor: pointer;
}

.doctor-panel-filters {
    align-items: center;
}

.doctor-panel-filters button {
    margin-top: 30px;
}

.doctor-w-100 {
    width: 100%;
}

.btns-group-patient {
    justify-content: space-around;
}

.doctor-symptoms {
    display: grid;
    padding-left: 25px;
}

.doctor-symptoms span{
    margin-right: 20px;
    color: #56be76;
    font-size: 16px;
    /* overflow-wrap: anywhere; */
}

.fc-timegrid-event .fc-event-main {
    padding: 0px;
    margin: 0px;
    margin-top: -5px;
}

.bg-red {
    background-color: red;
    border-color: red;
}

.mrn-auto {
    margin-left: auto;
}

.files-dropzone {
    padding: 10px;
    border: 1px dashed #d3d3d3;
    min-height: 120px;
    width: 100%;
    align-items: center !important;
    display: flex;
    justify-content: center !important
  }

  .container-doc-em {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .container-doc-em input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  .container-doc-em:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .container-doc-em input:checked ~ .checkmark {
    background-color: #60d16d;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container-doc-em input:checked ~ .checkmark:after {
    display: block;
  }
  
.container-doc-em .checkmark:after {
		top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.doc-slot {
	display: grid;
	margin-top: 10px;
}

.doc-slot li {
	width: 100%;
}

.doc-slot li a {
	background-color: #e9e9e9;
	border: 1px solid #e9e9e9;
	border-radius: 3px;
	color: #757575;
	display: block;
	font-size: 14px;
	margin-bottom: 10px;
	padding: 5px 5px;
	text-align: center;
	position: relative;
	cursor: pointer;
}
.doc-slot .selected {
	background-color: #42c0fb;
	border: 1px solid #42c0fb;
	color: #fff;
		
}
.day-slot .li-doc {
	width: 50%;
}
.doct-panel-em .slick-list {
	padding: 50px 60px;
}
.slick-dots {
	display: none !important;
}

.slot-online {
	border-color: rgb(44, 127, 183) !important;
	background-color: rgb(44, 127, 183) !important;
	color: #fff !important;
}
.slot-offline {
	border-color: rgb(86, 190, 115) !important;
	background-color: rgb(86, 190, 115) !important;
	color: #fff !important;
}

.selected-slot {
	border-color: red !important;
	background-color: red !important;
	color: #fff !important;
}

.chat-cont-right .chat-header {
	background-color: #5156be !important;
	color: #fff !important;
}
.chat-cont-right .media-body .user-name {
	color: #fff !important;
}
.chat-cont-right .chat-options i {
	font-size: 40px;
	color: #fff;
}
.chat-cont-right .chat-footer {
	background-color: #5156be;
	color: #fff !important;
}
.chat-footer .input-group-prepend i {
	color: #fff !important;
	font-size: 30px;
}
.chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
	background: #fff !important;
	color: #5156be;
}


.css-26l3qy-menu {
    z-index: 99999999 !important;
}

.success-bmi {
    background: #b6e297 !important; 
    color: #000 !important;
  }
  .under-bmi {
    background: #f8ccce !important;
    color: #000 !important;
  }
  .over-bmi {
    background: #fcfbc2 !important;
    color: #000 !important;
  }
  .obesity-bmi {
    background: #dd8990 !important;
    color: #000 !important;
  }

  .color-white {
    color: #000 !important;
  }
  .color-white h5 {
    color: #000 !important;
  }

  .doctor-add-patient-form .PhoneInputInput {
		height: 45px;
		border: 1px solid #dcdcdc;
		border-radius: 6px;
		padding-left: 6px;
	}

	.doctor-add-patient-form .PhoneInput:focus,
	.doctor-add-patient-form .PhoneInputInput:focus  {
		outline: none;
  }

.accordion-card-appt {
  margin-left: -15px;
  margin-right: -15px;
}

.accordion-card-appt:last-child {
  border: none;
}

.accordion-card-appt {
  background: #fff;
  border: 1px solid #ccc;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-card-appt .card-header {
  background: #fff;
  border-radius: 0px !important;
}

.accordion-card-appt button.btn.btn-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #272b41;
  margin: 0px;
  padding: 0px;
}

.accordion-card-appt button.accordion-viw-apt {
  background: #5156be;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px 12px;
  align-items: center;
  font-size: 20px;
}
.patient-detail-info h3 {
  padding: 0px 20px 0px 20px;
}
.patient-detail-info .patient-details {
  text-align: start;
}
.patient-detail-info {
  text-align: start;
}
.patient-detail-info a {
  color: rgb(81 86 190);
  padding: 0px 20px 0px 20px;
  font-weight: 700;
  line-height: 39px;
  font-size: 14px;
}
.patient-detail-info a:not([href]):hover {
  color: rgb(81 86 190);
}

.patient-info-modal-close-icon {
  position: sticky;
  margin-left: auto;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #888993;
  cursor: pointer;
}
 
  @media only screen and (max-width: 767.98px) {
    .d-flex {
      display: block ;
    }
    .doctor-active-btn, .doctor-btn-outline {
      margin-bottom: 10px;
    }
    .table-action a, .table-action div {
      margin-bottom: 10px;
    }
  }

@media only screen and (max-width: 456px) {
    .mobile-screen-dialog-centered {
      max-width : 100% !important;
    }
    .mobile-screen-dialog-main {
      margin-top: 0!important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .mobile-screen-card-body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
}
  