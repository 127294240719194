.card {
	border-radius: 20px;
	border: 1px solid #5156be;
}

.card-header {
	border-radius: 20px !important;
}

.card-header h5 {
	padding: 0px;
}

.card-header h5 p {
	color: #3abe72;
	padding: 0px;
}

.react-time-picker {
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
}

.react-time-picker__wrapper {
	border-radius: 6px;
	padding: 5px;
	height: 46px;
	border: 1px solid #ccc;
}
.drug-administration-check-circle {
	font-size: 30px;
	/* color: #34e780; */
	text-align: center;
}


.drug-administration-input {
	margin: auto;
}

.camera-icon {
	font-size: 50px;
	color: #34e780;
}



.drug-administration-check-circle .container {
	display: flex;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	justify-content: center;
  }
  .drug-administration-check-circle .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  .drug-admin-checkmark {
	position: absolute;
	top: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
  	border-radius: 15px;
  }
  .drug-administration-check-circle .container:hover input ~ .drug-admin-checkmark {
	background-color: #ccc;
  }
  .drug-administration-check-circle .container input:checked ~ .drug-admin-checkmark {
	background-color: #3abe72;
  }
  .drug-admin-checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  .drug-administration-check-circle .container input:checked ~ .drug-admin-checkmark:after {
	display: block;
  }
  
  .drug-administration-check-circle .container .drug-admin-checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45desg);
	transform: rotate(45deg);
  }
.lab-investigation-card {
	border: 2px solid rgba(12, 11, 11, 0.678);
	background-color: #f5f5f6;
	height: 300px; /* cards become too small when fields are editable  deps: subuser/view-patient  */
	padding: 0px;
	border-radius: 5px;
}

.lab-investigation-card .list-unstyled > div .chat-msg-info {
    flex-direction: row-reverse;
}
.lab-investigation-card .list-unstyled .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.lab-investigation-card .list-unstyled .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.lab-investigation-card .list-unstyled .media.received {
	margin-bottom: 20px;
}
.lab-investigation-card .list-unstyled .media.received .media-body .msg-box > div {
	background-color: #fff;
	max-width: 80%;
	height: auto;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}
.lab-investigation-card .list-unstyled .media-body .msg-box {
	position: relative;
}

.lab-investigation-input-box {
	height: 15%;
}

.lab-investigation-card .list-unstyled {
	overflow-y: scroll;
	height: 75%;
	padding: 10px;
}

.list-footer-div .chat-footer {
	/* background-color: #59bf76; */
	color: #fff !important;
}

.list-footer-div .chat-footer .input-group-prepend i {
	color: #fff !important;
	font-size: 30px;
}

.list-footer-div .chat-footer .input-group-prepend i {
	color: #fff !important;
	font-size: 30px;
}
.list-footer-div .btn.msg-send-btn {
	background: #fff !important;
	color: #5156be;
}

.list-footer-div {
	padding: 5px 0px;
	background-color: #5156be;
	height: 58px;
}

.file-drop-list {
	min-height: 30px;
	height: 48px;
}
.todoDayTime {
	max-width: 100% !important;
}

.todoStrikeThrough {
	text-decoration: line-through;
}
.react-date-picker--open {
	z-index: 1000;
}
.filter-emed4u-sml-btn {
    background-color: #5156be;
    border-color: #5156be;
    color: #ffffff;    
    display: inline-block;
    overflow: hidden;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    transition: .3s ease-out;
    padding: 0.375rem 0.75rem !important;
    font-size: .82rem;
    line-height: 1.35;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    cursor: pointer;
    font-weight: 400;
    text-transform: none;
 }
 .activebutton {
	 background-color: transparent !important;
	 color: #5156be;
	 font-weight: 500;
 }
 .activebutton:hover {
	 color: #5156be;
 }
 .cards-main-heading {
	 font-size: 1.4em;
 }

 .warning-card {
	 background-color: #FACC48;
	 color: #FFF;
	 font-size: 20px;

 }

@media only screen and (max-width: 450px) {
	.modal-dialog-centered {
		max-width: 100% !important;
	}
	.card-body {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
