/* @import url('../../../../constant/colors.css'); */
:root {
    --white: #ffffff;
    --black: #000000;
    --black1: #2B2B2B;
    --black2: #1C2134;
    --grey: #CCCCCC;
    --grey1: #5E5E5E;
    --grey2: #333333;
    --red: red;
    --white1: #F5F5F7;
    --white2: #f0f0f0;
    --white3: #F8F7F7;
    --green: green;
    --errorTextColor: #9F215F;
    --primary: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
  }
  
.login-modal-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0 15px;
}
.footer-style-signup {
    color: #2b2b2b;
}
.sign-color {
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
}
.forget-password-style {
    margin: 1px 0 45px !important;
    text-align: end;
    cursor: pointer;
    background: var(--primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.button-login-style {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;
}
.button-proced-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-proced-style {
    width: 200px;
    height: 48px;
    border-radius: 8px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.button-opacity-05 {
    background: linear-gradient(90deg, rgba(9, 126, 183, 0.3) 0%, rgba(42, 171, 108, 0.3) 100%);
    cursor: not-allowed !important;
}

.button-opacity-0 {
    background: var(--primary);
    cursor: pointer !important;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    margin-bottom: 10px;
}

.strike > span {
    position: relative;
    display: inline-block;
    color: var(--grey1);
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #CCCCCC;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.inputText{
    border-color: #dcdcdc;
    color: var(--grey2);
	font-size: 15px;
    min-height: 46px;
	padding: 6px 15px;
    border-radius: 8px !important;
    background: #F8F7F7 !important;
}

i.fa.fa-eye{
    display: none;
}

.password-out{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pass-container{
    width: 48%;
}

.inputTextNumber{
    color: #333;
	font-size: 15px;
    border-radius: 8px !important;
    background: #F8F7F7 !important;
}
.inputTextNumber input{
    background: #F8F7F7 !important;
    border-radius: 0 8px 8px 0 !important;
    border-color: #dcdcdc !important;;
}

.inputTextNumber .PhoneInputCountry{
    border-radius: 8px 0 0 8px !important;
}

.button-login-style{
    margin-bottom: 40px;
}
.modal-footer{
    border: none;
}

.form-width {
    margin: 0 63px 0;
}