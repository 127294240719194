
.ContactUsModal{
    overflow: scroll;
    z-index: 99999999;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.ContactUsModal::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.ContactUsModal::-webkit-scrollbar-thumb{
    background: none
}

.ContactUsModal .modal-dialog .modal-content{
    min-width: 700px !important;
}
.ContactUsModal .customInput{
    width: 45%;
}

.ContactUsModal .mobilePadding{
    padding: 0 60px;
}

.button-position {
    padding: 0 60px 36px !important;
    display: flex;
    justify-content: flex-end;
    width: -moz-available;
    width: -webkit-fill-available;
}
@media only screen and (max-width: 767px) {
  
    .ContactUsModal .mobilePadding{
        padding: 0px;
    }
    .ContactUsModal .modal-dialog .modal-content{
        min-width: 320px !important;
    }

    .ContactUsModal .customInput{
        width: 100%;
    }
    .button-position {
        display: flex;
        justify-content: center;
    }
}