.toolbar button {
    padding: 4px 20px;
    border: 1px solid #b1aacc;
    background: #b1aacc;
    color: #FFF;
    letter-spacing: 1px;
    border-radius: 6px;
    margin: 5px 10px;
}

.toolbar .active {
    border: 1px solid #5156be;
    background: #5156be;
}