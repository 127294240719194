.treatment-heading {
    color: #5156be;
}

.cust-do-em-treatment {
    display: flex !important;
    margin-bottom: 1.25rem !important;
    align-items: flex-end;
  }

@media only screen and (max-width:768px) {
	.treatment-header .doctor-active-btn {
        font-size: 12px;
        padding: 5px !important;
    }
    .treatment-heading {
        font-size: 12px;
    }
}